<!-- eslint-disable vue/no-v-html -->
<template>
	<v-layout v-if="application" column>
		<v-layout shrink row align-center>
			<v-flex xs-6 ma-3 justify-center text-xs-center title>
				<v-img contain max-height="15vh" max-width="15vw" :src="application.logo" />
				<v-flex mt-3>
					<em>{{ application.baseline }}</em>
				</v-flex>
			</v-flex>
			<v-flex xs6 text-xs-center title v-text="application.type" />
		</v-layout>
		<v-flex v-if="application.description_long" ma-3>
			<v-flex title mb-3>{{ $t('description') }} :</v-flex>
			<p v-html="application.description_long" />
		</v-flex>
		<v-flex v-if="application.video" ma-3 justify-center>
			<div class="videoWrapper">
				<iframe
					width="100%"
					height="100%"
					title=""
					:src="application.video"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					allowfullscreen
					frameborder="0"
				/>
			</div>
		</v-flex>
		<v-flex v-if="application.price_txt" ma-3>
			<v-flex v-if="application.price_txt" title mb-3>{{ $t('pricing') }} :</v-flex>
			<v-flex mb-2 v-html="application.price_txt"></v-flex>
			<v-img v-if="application.price_image" class="ma-3" :src="application.price_image"></v-img>
		</v-flex>
		<v-flex ma-3>
			<v-flex title mb-3>{{ $t('web_page') }} :</v-flex>
			<v-flex class="pointer" @click="openUrl(application.site_url)" v-text="application.site_url" />
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	name: 'ApplicationDetails',
	props: {
		value: {
			default: null,
			required: false,
			type: Object
		}
	},
	computed: {
		application: function () {
			return this.value
		}
	},
	methods: {
		openUrl: function (url) {
			window.open(url)
		}
	}
}
</script>
<style>
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
</style>
